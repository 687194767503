<template>
  <!-- 开发人员：张亚欣 -->
  <div class="box">
    <div style="background: rgb(250, 250, 249)">
      <div style="position: relative; height: 100%; min-width: 12px">
        <div class="icon-box" @click="toggleLeft">
          <i class="el-icon-caret-left icon" v-show="isShow"> </i>
          <i class="el-icon-caret-right icon" v-show="!isShow"> </i>
        </div>

        <div class="left-content" v-show="isShow">
          <!-- 切换视图 -->
          <choose-view
            class="pull-left choose-view"
            :object-name="tabName"
            :objId="objId"
            :viewId="viewId"
            :view-list="viewList"
            :view-name="viewName"
            :tabStyle="tabStyle"
            @changeView="changeView"
            v-if="this.viewLength > 0"
          >
          </choose-view>

          <!-- 数据列表说明文字 -->
          <statistics
            :total="totalSize"
            :field="sortFieldName"
            :isQueryCount="isQueryCount"
            :time-interval="timeInterval"
            :show-checked-length="false"
            @refresh="refresh"
          >
          </statistics>
          <!-- 视图相关操作按钮 -->
          <view-buttons
            class="pull-right viewButtons"
            :show-buttons="showButtons"
            :objId="objId"
            isActive="multiScreen"
            :multi-screen="true"
            @boardView="boardView"
            @planView="planView"
            @tableView="tableView"
          >
          </view-buttons>

          <!-- 搜索框 -->
          <search-wrapper
            width="calc(50% - 10px)"
            class="searchWrapper pull-left"
            @changeKeyWord="changeKeyWord"
            :exact-search-flag="exactSearchFlag"
            :exact-search-field-list="exactSearchFieldList"
          >
          </search-wrapper>

          <!-- 列表信息 -->
          <div class="sort-content">
            <!-- 排序依据: -->
            {{ $t("label.orderby") }}
            <span>
              <!-- 默认按照创建时间降序排序 -->
              {{ !sortFieldName  ? $t('label.createdate') : sortFieldName }}
              <svg
                class="icon icon-sort"
                aria-hidden="true"
                :style="{
                  transform: sortDir === 'asc' ? '' : 'rotate(180deg)',
                }"
              >
                <use href="#icon-icon_sort_top"></use>
              </svg>
            </span>
          </div>
          <Wang-skeleton
            type="line"
            :options="skeletonOptions"
            v-if="pageNum === 1"
          >
          </Wang-skeleton>
          <ul
            class="dataList pull-left"
            @scroll="scrollDataList"
            v-if="pageNum !== 1"
          >
            <li
              v-for="item in pageObj.dataList"
              :key="item.id"
              class="data-box pull-left"
              @click="showDetail(item)"
            >
              <div
                class="data-item pull-left"
                :class="{ 'text-align-right': index % 2 !== 0 }"
                v-for="(field, index) in headLabelList"
                :key="field.schemefieldName"
                :style="{
                  color: index === 0 ? '#080707' : '#666',
                  clear: index === 2 ? 'both' : '',
                }"
              >
                <!-- 标签 -->
                <span
                  class="cannotEdit"
                  v-if="
                    field.schemefieldType === 'X' &&
                    field.apiname === 'cloudcctag'
                  "
                >
                  <el-tag
                    v-for="tag in item['cloudcctagList']"
                    :key="tag.name"
                    :type="
                      tag.color === 'lan'
                        ? ''
                        : tag.color === 'hong'
                        ? 'danger'
                        : tag.color === 'lv'
                        ? 'success'
                        : tag.color === 'hui'
                        ? 'info'
                        : tag.color === 'huang'
                        ? 'warning'
                        : ''
                    "
                    effect="dark"
                    class="tag"
                  >
                    {{ tag.name }}
                  </el-tag>
                </span>
                <!-- 潜在客户打分 -->
                <span
                  v-else-if="field.schemefieldName === 'leadscore'"
                  class="cannotEdit"
                >
                  <span
                    style="
                      float: left;
                      width: 20px;
                      margin-left: 14px;
                      display: inline-block;
                    "
                  >
                    {{ item[`${field.schemefieldName}`] }}
                  </span>
                  <el-progress
                    :percentage="
                      item[`${field.schemefieldName}`] !== undefined &&
                      item[`${field.schemefieldName}`] !== null
                        ? Number(item[`${field.schemefieldName}`])
                        : 0
                    "
                    :color="customColors"
                    :format="formatProgress"
                  >
                  </el-progress>
                </span>
                <!-- 评分 -->
                <span
                  v-else-if="field.schemefieldType === 'SCORE'"
                  class="cannotEdit stars"
                  style="display: contents"
                >
                  <span
                    v-for="(star, idx) in Number(field.schemefieldLength) + 1"
                    v-show="idx !== 0"
                    :class="{
                      show2: idx <= Number(item[`${field.schemefieldName}`]),
                    }"
                    :key="idx"
                  >
                  </span>
                </span>
                <!-- 查找、主详、选项列表 -->
                <span
                  v-else-if="
                    field.schemefieldType === 'Y' ||
                    field.schemefieldType === 'M' ||
                    field.schemefieldType === 'R' ||
                    field.schemefieldType === 'MR'
                  "
                >
                  {{ item[`${field.apiname}ccname`] }}
                </span>
                <!-- 日期 -->
                <span v-else-if="field.schemefieldType === 'D'">
                  {{
                    item[`${field.schemefieldName}`] | dateFormat(countryCode)
                  }}
                </span>
                <!-- 日期时间 -->
                <span v-else-if="field.schemefieldType === 'F'">
                  {{
                    item[`${field.schemefieldName}`]
                      | datetimeFormat(countryCode)
                  }}
                </span>
                <!-- 复选框 -->
                <span v-else-if="field.schemefieldType === 'B'">
                  {{
                    item[`${field.schemefieldName}`] === "true"
                      ? "是"
                      : item[`${field.schemefieldName}`] === "false"
                      ? "否"
                      : ""
                  }}
                </span>
                <span v-else>
                  {{ item[`${field.schemefieldName}`] }}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div style="width: 100%; padding: 0; overflow: auto">
      <div class="right-content">
        <div v-if="noDetailt" style="height: 100%; background: #fff">
          <div v-if="noDetailt" class="noDetailt">
            <svg class="icon icon-noData" aria-hidden="true">
              <use href="#icon-email4"></use>
            </svg>
            <p style="margin: 0">
              <!-- 未选择记录 -->
              {{ $t("vue_label_SMS_noselected") }}
            </p>
            <p>
              <!-- 从列表中打开,以开始使用 -->
              {{ $t("vue_label_commonobjects_view_open_from_list") }}
            </p>
          </div>
        </div>

        <!-- 数据详情 - 通用对象详情-->
        <detail
          ref="detail"
          style="padding: 10px 10px 0px 10px"
          v-if="!noDetailt && prefix !== 'p01'"
          :data-id="id"
        />
        <!-- 项目详情 -->
        <project-detail
          ref="detail"
          style="padding: 10px 10px 0px 10px"
          v-if="!noDetailt && prefix === 'p01'"
          :data-id="id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Detail from "../commonObjects/detail";
import ProjectDetail from "../commonObjects/project-detail/index.vue";
import {
  ViewButtons,
  ChooseView,
  SearchWrapper,
  Statistics,
} from "@/components/index";
import * as CommonObjApi from "./api.js";
import * as SetViewApi from "./api.js";
import Vue from "vue";
import vueWangIview from "vue-wang-iview"; // 骨架屏
import "vue-wang-iview/dist/vue-wang-iview.css";
Vue.use(vueWangIview);
export default {
  components: {
    ViewButtons,
    ChooseView,
    SearchWrapper,
    Statistics,
    Detail,
    ProjectDetail,
  },
  data() {
    return {
      countryCode: this.$cookies.get("countryCode"),
      skeletonOptions: {
        active: true,
        row: 15,
        column: 2,
        width: "10vw",
        height: "20px",
        justifyContent: "space-around",
      },
      id: "",
      objId: this.$route.params.objId, // 对象id
      prefix: this.$route.params.prefix, // 对象前缀
      objectApi: this.$route.params.objectApi,
      tabName: "",
      viewName: "", // 视图名称
      viewId: this.$route.query.viewId, // 视图id
      viewList: [], // 视图列表
      viewLength: 0,
      isQueryCount: false,
      searchKeyWord: "",
      conditionValues: "",
      tagIds: [], // 搜索标签
      customColors: [
        { color: "#70acf1", percentage: 20 },
        { color: "#f7c97e", percentage: 40 },
        { color: "#faa58c", percentage: 100 },
      ],
      isAllTag: "", // 是否同时满足所有标签
      isallsearch: "false",
      myself: "false",
      scrollId: "false",
      noDetailt: true,
      currIndex: 0,
      isCollapse: false,
      isShow: true,
      headLabelList: null,
      pageNum: 1, //页码默认为1
      pageSize: 20, // 每页展示数据条数
      totalSize: 0,
      pageObj: {
        dataList: [],
      },
      noDataTip: false, // 无更多数据
      sortField: this.$route.query.sortField,
      sortDir: this.$route.query.sortDir, // 排序方式
      sortFieldName: this.$route.query.sortFieldName,
      showButtons: ["liebiaoshitu", "kanbanshitu", "fenpingshitu"],
      dataListHeight: `${document.body.clientHeight - 419}px` || "100%",
      timer: "", //定义一个定时器的变量
      lastRefresh: new Date(), // 最后一次更新时间
      timeInterval: "",
      tabStyle: "",
      exactSearchFlag: "", // 是否支持单字段搜索标识
      exactSearchFieldList: [], // 单字段搜索字段集合
      // 分屏视图项目任务跳转至新的看板任务需要的参数
      splitScreenTasksObjectApi: "",
    };
  },
  created() {
    this.objId = this.$route.params.objId;

    this.getViewList();
    this.getViewListRecently();
    // 计时器计算多久前更新
    this.timer = setInterval(() => {
      // 获取当前时间
      let currentTime = new Date();
      let interval = currentTime - this.lastRefresh;

      //计算出相差天数
      var days = Math.floor(interval / (24 * 3600 * 1000));
      //计算出小时数
      var leave1 = interval % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000));
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000));
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      hours = minutes > 30 ? hours + 1 : hours;
      if (days > 0) {
        // this.timeInterval = hours > 0 ? `${days}天${hours}小时前` : `${days}天前`
        this.timeInterval =
          hours > 0
            ? `${days} ${this.$i18n.t(
                "label.login.access.day"
              )}${hours} ${this.$i18n.t("label_tabpage_hoursagoz")}`
            : `${days} ${this.$i18n.t("label_tabpage_Sdfez")}`;
      } else if (hours > 0) {
        // 小时前
        this.timeInterval = `${hours} ${this.$i18n.t(
          "label_tabpage_hoursagoz"
        )}`;
      } else if (minutes > 0) {
        // 分钟前
        this.timeInterval = `${minutes} ${this.$i18n.t(
          "label_tabpage_minutesagoz"
        )}`;
      } else {
        this.timeInterval =
          seconds > 1
            ? this.$i18n.t("vue_label_commonobjects_view_secondsbefore", {
                seconds: seconds,
              })
            : this.$i18n.t("label.notification.time1");
      }
    }, 1000);
  },
  methods: {
    // 潜在客户热度设置信息
    getLeadHeat() {
      if (localStorage.getItem("coldLevel") === null) {
        CommonObjApi.getLeadHeat({}).then((res) => {
          localStorage.setItem("coldLevel", Number(res.data.coldLevel) + 1);
          localStorage.setItem("hotLevel", Number(res.data.hotLevel) + 1);

          this.customColors[0].percentage = localStorage.getItem("coldLevel");
          this.customColors[1].percentage = localStorage.getItem("hotLevel");
        });
      } else {
        this.customColors[0].percentage = localStorage.getItem("coldLevel");
        this.customColors[1].percentage = localStorage.getItem("hotLevel");
      }
    },
    // 进度条格式化
    formatProgress() {
      return "";
    },
    active(index) {
      this.currIndex = index;
    },
    handleOpen() {},
    handleClose() {},
    // 切换视图
    changeView(view) {
      this.viewList.map((item) => {
        // 获取默认视图信息
        if (item.id === view.id) {
          this.viewName = item.label;
          this.viewId = item.id;
          item.isCurrent = true;
        } else {
          item.isCurrent = false;
        }
      });

      // 刷新视图列表
      this.refresh();
      // 按钮权限控制
      this.ismodify();
    },
    // 获取视图列表
    getViewList() {
      this.viewName = "";

      SetViewApi.GetViewList({
        objId: this.objId,
      }).then((res) => {
        this.viewList = res.data;
        this.viewList.map((item) => {
          // 获取列表视图/看板视图带过来的视图信息
          if (item.id === this.viewId) {
            item.isCurrent = true;
            this.viewName = item.label;
          } else {
            item.isCurrent = false;
          }
        });
        this.viewLength = this.viewList.length;
        this.getViewListHeader();
        this.getViewListData();
        // 按钮权限控制
        this.ismodify();
      });
    },
    // 获取视图列表按钮权限
    getViewListRecently() {
      this.exactSearchFlag = "";
      this.exactSearchFieldList = [];

      CommonObjApi.getViewListRecently({
        obj: this.prefix,
        viewId: this.viewId,
      }).then((res) => {
        this.tabStyle = res.data.tabStyle;
        this.tabName = res.data.label;
        this.exactSearchFlag = res.data.exactSearchFlag || "";
        this.exactSearchFieldList = res.data.exactSearchFieldList || [];
        // 是否启用查找统计
        this.isQueryCount = res.data.isQueryCount;
      });
    },
    // 获取视图列表表头
    getViewListHeader() {
      CommonObjApi.getViewListHeader({
        obj: this.prefix,
        viewId: this.viewId,
      }).then((res) => {
        res.data.headLabel.forEach((head, idx) => {
          if (head.schemefieldType === "X") {
            res.data.headLabel.splice(idx, 1);
          }
          // 潜在客户评分
          if (head.schemefieldName === "leadscore") {
            this.getLeadHeat();
          }
        });
        this.headLabelList =
          res.data.headLabel.length > 3
            ? res.data.headLabel.slice(0, 4)
            : res.data.headLabel;
      });
    },
    // 获取视图数据
    getViewListData() {
      /**
       * 参数说明
       * 参数名	必选	类型	说明
       * obj	是	string	对象前缀
       * viewId	是	string	视图Id
       * pageNum	是	string	页数
       * pageSize	是	string	每页数量
       * sortField	是	string	排序字段apiname
       * sortDir	是	string	排序类型(desc[降序]/asc[升序])
       * searchKeyWord	是	string	搜索关键字
       * conditionValues	是	string	高级搜索条件
       * ischangenum	是	string	是否修改每页查询数目（修改的数目为视图默认每页数量）
       * recordnums	是	string	每页查询默认数目
       * tagIds	否	string	标签id，多个标签逗号分隔，按选中标签查询
       * isAllTag	否	string	是否同时满足所有选中标签（true/false）
       * campaignid	否	string	市场活动成员是否只查询现有成员
       * isallsearch	否	string	是否启用启用全局搜索，conditionValues不用空也不启用全局搜索
       * myself	否	string	全局搜索是否只查询所有人是自己的记录
       * scrollId	否	string	全文检索查询返回值，下一页查询回传
       * type	否	string	contact/lead特殊查询使用以不使用全文检索，参数暂时不用
       */

      this.noDataTip = false;
      this.getViewListDataParams = {
        obj: this.prefix,
        viewId: this.viewId,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        sortField: this.sortField,
        sortDir: this.sortDir,
        searchKeyWord: this.searchKeyWord,
        conditionValues: this.conditionValues,
        ischangenum: "false",
        recordnums: this.pageSize,
        tagIds: this.tagIds.toString(),
        isAllTag: this.isAllTag,
        campaignid: "false",
        isallsearch: this.isallsearch,
        myself: this.myself,
        scrollId: this.scrollId,
        type: "",
        exactSearchFlag: this.exactSearchFlag,
      };
      CommonObjApi.getViewListData(this.getViewListDataParams).then((res) => {
        this.totalSize = res.data.totalSize;
        if (this.pageObj.dataList.length === 0 || this.pageNum === 1) {
          this.pageObj.dataList = res.data.list;
          // setTimeout(() => {
          //   this.pageNum++;
          // }, 600);
          this.pageNum++;
          // 分屏视图右侧默认展示左侧列表第一条数据
          this.id = this.pageObj.dataList[0].id;
          this.noDetailt = false;
          this.$nextTick(() => {
            this.$refs.detail.init();
          });
        } else {
          this.pageObj.dataList = [...this.pageObj.dataList, ...res.data.list];
          this.pageNum++;
        }
        // 判断是否有更多数据
        if (res.data.haveMore === "false") {
          this.noDataTip = true;
        }
        if (this.id === "") {
          // 分屏视图右侧默认展示左侧列表第一条数据
          this.id = this.pageObj.dataList[0].id;
          this.noDetailt = false;
          this.lastRefresh = new Date();
        }
      });
    },
    // 节流函数
    fnThrottle(method, delay, duration) {
      var that = this;
      var timer = this.timer;
      var begin = new Date().getTime();
      return function () {
        var current = new Date().getTime();
        clearTimeout(timer);
        if (current - begin >= duration) {
          method();
          begin = current;
        } else {
          that.timer = setTimeout(function () {
            method();
          }, delay);
        }
      };
    },
    // 滚动加载
    scrollDataList() {
      let elem = document.getElementsByClassName("dataList")[0];

      if (elem.scrollHeight - elem.scrollTop - elem.clientHeight <= 1) {
        if (!this.noDataTip) {
          this.fnThrottle(this.getViewListData, 500, 1000)();
        }
      }
    },
    // 数据详情
    showDetail(data) {
      this.id = data.id;
      this.noDetailt = false;
      this.$nextTick(() => {
        this.$refs.detail.init();
        this.$refs.detail.multiTask();
      });
    },
    // 列表视图
    tableView() {
      //知识文章列表
      if (this.objectApi == "CloudccKArticle") {
        this.$router.push({
          path: `/Knowledgearticles/CloudccKArticle/cloudcc_k_article/k02`,
          query: { viewId: this.viewId },
        });
      } else {
        this.$router.push({
          path: `/commonObjects/views/table/${this.objectApi}/${this.objId}/${this.prefix}`,
          query: { viewId: this.viewId },
        });
      }
    },
    // 看板视图
    boardView() {
      // 项目任务跳转百分比页面
      if (this.splitScreenTasksObjectApi === "cloudccTask") {
        let tasjId = "ffe20201207task09";
        localStorage.setItem("key", tasjId);
        this.$router.push({
          path: `/projectTasks`,
        });
      } else {
        this.$router.push({
          path: `/commonObjects/views/kanban/${this.objectApi}/${this.objId}/${this.prefix}`,
          query: { viewId: this.viewId },
        });
      }
    },
    // 轮班计划视图
    planView() {
      this.$router.push({
        path: `/commonObjects/views/plan/${this.objectApi}/${this.objId}/${this.prefix}`,
        query: {
          viewId: this.viewId,
        },
      });
    },
    // 刷新
    refresh() {
      this.sortField = "";
      this.sortFieldName = "";
      // 重置列表信息
      this.pageObj.dataList = [];
      this.pageNum = 1;

      this.getViewListRecently();
      this.getViewListHeader();
      this.fnThrottle(this.getViewListData, 500, 1000)();
    },
    // 搜索条件改变
    changeKeyWord(val) {
      this.pageNum = 1;
      this.pageObj.dataList = [];
      this.searchKeyWord = val;
      this.getViewListData();
    },
    // 控制左侧内容显示/隐藏
    toggleLeft() {
      this.isShow = !this.isShow;
      // this.isCollapse = !this.isCollapse
    },
    // 看板视图仅在非四个特殊视图下展示
    ismodify() {
      if (
        this.viewId === "0" ||
        this.viewId === "1" ||
        this.viewId === "2" ||
        this.viewId === "3"
      ) {
        this.showButtons = ["liebiaoshitu", "fenpingshitu"];
      } else {
        this.showButtons = ["liebiaoshitu", "kanbanshitu", "fenpingshitu"];
      }
    },
  },
  watch: {
    // 获取路由
    $route: {
      handler: function (route) {
        // 获取到项目任务页面对象api
        this.splitScreenTasksObjectApi = route.params.objectApi;
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
// 评分字段显示
.stars {
  list-style: none;
  margin: 0;
  word-break: break-all;
  color: #ccc;
  float: left;
}

.stars span {
  font-size: 20px;
  margin-left: 3px;
}

.stars span:after {
  content: "☆";
}

.stars .show:after,
.stars .show2:after {
  content: "★";
}

.show2 {
  color: #f7ba2a;
}

.box {
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  // margin-left: 10px;

  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 100%;
    height: 100%;
    position: relative;
    // padding: 10px 26px 0 0;
    padding: 10px 12px 0 0;
  }

  ::v-deep .el-menu--collapse {
    width: 17px;
    height: 100%;
  }

  .sort-content {
    clear: both;
    font-size: 12px;
    border-top: 1px solid #dddbda;
    border-bottom: 1px solid #dddbda;
    padding-left: 20px;
    width: calc(100% - 17px);
    height: 40px;
    line-height: 40px;
    color: #333;

    .icon-sort {
      height: 12px;
      vertical-align: "";
      display: inline-block;
    }

    span {
      color: #666;
      margin-left: 18px;
    }
  }

  .icon-box {
    position: absolute;
    top: 0;
    right: 1px;
    height: 100%;
    width: 12px;
    border: 1px solid #ccc;
    cursor: pointer;
    background: #fff;
    box-shadow: -1px 0 1px #ccc;
    // box-shadow: 0 1px 2px 1px #ccc;

    .icon {
      position: absolute;
      top: 50%;
      left: -1px;
      font-size: 12px;
      color: #666;
    }
  }

  .editableCellBox {
    width: calc(33.33% - 17px) !important;
  }

  .dataList {
    width: calc(100% - 13px);
    height: calc(100% - 185px);
    // padding-right: 6px;
    overflow: hidden;
    margin-bottom: 0;
    &:hover {
      overflow: auto;
    }

    .cannotEdit {
      .tag {
        margin: 3px;
      }
    }

    .data-box {
      width: 94%;
      padding: 10px;
      cursor: pointer;
      border-bottom: 1px solid #dddbda;

      &:hover,
      &:active {
        background: #fff;
      }

      .data-item {
        width: 50%;
        min-height: 18px;
        // line-height: 32px;
        font-size: 12px;
        // min-height: 18px;
        // height: auto;
        color: #666;
      }

      .text-align-right {
        text-align: right;
      }
    }
  }

  .left-content {
    width: 400px;
    height: 100%;
    border-radius: 10px;
    float: left;
    overflow: hidden;
  }
  .right-content {
    width: 100%;
    height: 100%;
    overflow: auto;

    .noDetailt {
      width: calc(100% - 1px);
      height: 100%;
      /* padding-top: calc(50% - 153px); */
      text-align: center;
      background: #fff;
      position: relative;
      background: #fff;
      left: 1px;
      top: calc(50% - 153px);

      .icon-noData {
        width: 100%;
        height: 264px;
      }
    }
  }

  .choose-view {
    width: 100%;
    margin: 10px 0 12px 10px;
  }

  .searchWrapper {
    margin-bottom: 20px;
    // 分屏视图情况下距离上方距离
    margin-top: 10px;
  }

  .viewButtons {
    position: relative;
    // top: -14px;
    height: 28px;
    margin: 0 23px 0 0;

    .line-two-btn .group-btn {
      margin-top: 0;
      background-size: 56%;
      background-position-x: 6px;
    }
  }

  .statistics {
    clear: both;
    float: left;
    margin-left: 10px;

    .title {
      margin-bottom: 10px;
      // 防止过长影响右侧按钮，后期需要改为flex布局
      max-width: 240px;
    }
  }
}
</style>
